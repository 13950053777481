export enum ReportsStorageKeysEnum {
  AmountEquipmentSpentReportFilters = 'amount-equipment-spent-report/filters',
  HistoryNomenclatureOperationsReportFilter = 'history-nomenclature-operations-report/filters',
}

export enum FiscalAccumulatorFormatEnum {
  Dubbed = 'DUBBED',
  OutOfMemoryLess7 = 'OUT_OF_MEMORY_LESS_7',
  OutOfMemoryLess15 = 'DATE_EXPIRES_LESS_15',
  OutOfMemoryLess21 = 'DATE_EXPIRES_LESS_21',
}
