export enum UserStatusCodeEnum {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
  Break = 'BREAK',
}

export enum LocationTypeEnum {
  Shop = 'SHOP',
  Warehouse = 'WAREHOUSE',
  ServiceCenter = 'SERVICE_CENTER',
}
