export const getTimeZonesCatalogErrMsg = 'Ошибка получения временных зон'

export const getSubTaskTemplatesCatalogErrMsg = 'Не удалось получить шаблоны заданий'

export const getUserStatusesCatalogErrMsg = 'Ошибка получения статусов пользователя'
export const getLocationsCatalogErrMsg = 'Ошибка получения списка меcтонахождений'
export const getFaChangeTypesCatalogErrMsg =
  'Ошибка получения списка типов замены фискальных накопителей'

export const getResolutionClassificationsCatalogErrMsg =
  'Ошибка получения списка классификаций решения уровня 1'

export const getUserStatusesErrMsg = 'Ошибка получения статусов пользователя'
export const getLocationsErrMsg = 'Ошибка получения списка меcтонахождений'
export const getFaChangeTypesErrMsg = 'Ошибка получения списка типов замены фискальных накопителей'
export const getUrgencyRateTypesErrMsg =
  'Ошибка получения списка тарифов срочности для работ по изменению инфраструктуры'

export const getWorkGroupsCatalogErrMsg = 'Ошибка получения каталога рабочих групп'
export const getInfrastructureWorkTypesErrMsg =
  'Ошибка получения каталога видов работ по изменению инфраструктуры'
