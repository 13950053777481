export enum CatalogsApiEnum {
  GetTimeZoneList = '/catalogs/time-zones/',
  GetSubTaskTemplateList = '/catalogs/templates/',
  GetUserStatusList = '/catalogs/statuses/',
  GetLocations = '/catalogs/locations/',
  GetUrgencyRateTypes = '/catalogs/urgency-rate-types/',
  GetFaChangeTypes = '/fa-change-types/',
  GetResolutionClassifications = '/catalogs/resolution-classifications/',
  GetWorkGroups = '/catalogs/work-groups/',
  GetInfrastructureWorkTypes = '/catalogs/infrastructure-work-types/',
}
