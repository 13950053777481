export const getEmployeesActionsReportErrMsg =
  'Ошибка получения отчета по действиям сотрудника, находящегося в подчинении'

export const getEmployeesActionsReportXlsxErrMsg =
  'Ошибка получения отчета по действиям сотрудника, находящегося в подчинении в формате xlsx'

export const getAmountEquipmentSpentReportErrMsg =
  'Ошибка получения отчета по количеству потраченного оборудования'

export const getAmountEquipmentSpentReportXlsxErrMsg =
  'Ошибка получения отчета по количеству потраченного оборудования в формате xlsx'

export const getHistoryNomenclatureOperationsReportErrMsg =
  'Ошибка получения отчета по истории операций по номенклатуре'

export const getHistoryNomenclatureOperationsReportXlsxErrMsg =
  'Ошибка получения отчета по истории операций по номенклатуре в формате xlsx'

export const getFiscalAccumulatorTasksReportErrMsg =
  'Ошибка получения списка задач по фискальным накопителям'

export const getUsersMtsrReportErrMsg =
  'Ошибка получения данных для отчета основных показателей на уровне пользователей'

export const getWorkGroupsMtsrReportErrMsg =
  'Ошибка получения данных для отчета основных показателей на уровне рабочих групп'

export const getSupportGroupsMtsrReportErrMsg =
  'Ошибка получения данных для отчета основных показателей на уровне групп поддержки'

export const getMacroregionsMtsrReportErrMsg =
  'Ошибка получения данных для отчета основных показателей на уровне макрорегионов'

export const getInventorizationReportErrMsg = 'Ошибка формирования отчета по инвентаризации'
