export const getInventorizationErrMsg =
  'Ошибка получения детальной информации о поручении на инвентаризацию'

export const getInventorizationsErrMsg = 'Ошибка получения списка поручений на инвентаризацию'
export const createInventorizationErrMsg = 'Ошибка создания поручения на инвентаризацию'
export const completeInventorizationErrMsg = 'Ошибка выполнения поручения на инвентаризацию'

export const getInventorizationEquipmentsErrMsg =
  'Ошибка получения списка оборудования для инвентаризации'

export const getInventorizationEquipmentsXlsxErrMsg =
  'Ошибка скачивания файла списка оборудования для инвентаризации'

export const getInventorizationEquipmentErrMsg = 'Ошибка получения оборудования для инвентаризации'

export const createInventorizationEquipmentErrMsg =
  'Ошибка добавления оборудования при проведении сверки'

export const updateInventorizationEquipmentErrMsg =
  'Ошибка сохранения оборудования при проведении сверки'

export const getInventorizationEquipmentsTemplateErrMsg =
  'Ошибка формирования шаблона для проведения сверки оборудования из файла'

export const checkInventorizationEquipmentsTemplateErrMsg =
  'Ошибка загрузки оборудования из файла для сверки'

export const checkInventorizationEquipmentsErrMsg =
  'Ошибка сверки оборудования, загруженного из файла'
